import React from 'react';
import { Box } from 'rebass/styled-components';
import { useQaQueries } from '../queries/useQaQueries';
import Layout from '../components/Layout';
import { PageHeading, QaAccordion } from '../components';

const Faq = () => {
  const nodes = useQaQueries();

  return (
    <Layout>
      <Box variant="container" pb={['64px', '95px']}>
        <PageHeading subTitle="Q&A">常見問題</PageHeading>
        {nodes.map((Qa) => (
          <Box key={Qa.id} mb={['16px', '40px']}>
            <QaAccordion {...Qa} />
          </Box>
        ))}
      </Box>
    </Layout>
  );
};

export default Faq;
